module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-1814832-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","customEntries":[{"title":"Pictures","content":"awesome pictures","url":"/pictures"}]}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"collection","store":true},{"name":"idno","store":true},{"name":"type","store":true},{"name":"medium","store":true},{"name":"content"},{"name":"url","store":true}],"resolvers":{"MarkdownRemark":{}}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
