// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-content-page-js": () => import("/opt/build/repo/src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-content-post-js": () => import("/opt/build/repo/src/templates/content-post.js" /* webpackChunkName: "component---src-templates-content-post-js" */),
  "component---src-templates-catalog-entry-js": () => import("/opt/build/repo/src/templates/catalog-entry.js" /* webpackChunkName: "component---src-templates-catalog-entry-js" */),
  "component---src-templates-collections-js": () => import("/opt/build/repo/src/templates/collections.js" /* webpackChunkName: "component---src-templates-collections-js" */),
  "component---src-templates-artists-js": () => import("/opt/build/repo/src/templates/artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-types-js": () => import("/opt/build/repo/src/templates/types.js" /* webpackChunkName: "component---src-templates-types-js" */),
  "component---src-templates-media-js": () => import("/opt/build/repo/src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

